import styled from 'styled-components';

export default styled.input`
  border: 1px solid grey;
  border-radius: 0px;
  background: black;
  color: white;
  padding: 0.5rem;
  margin: 0.5rem 0;
  width: 100%;
  outline: none;
  box-sizing: border-box;
  &:focus {
    border-color: white;
  }
`;
