import { Grid } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';

import ContactForm from '../ContactForm';
import InternalLink from '../InternalLink';
import Layout from '../Layout';
import OriginalSubheading from '../Subheading';
import PageTitle from '../PageTitle';
import SmallText from '../SmallText';
import Textblob from '../Textblob';

const Subheading = styled(OriginalSubheading)`
  font-family: ffvCorporateFat, sans-serif;
  font-size: 1.4rem;
`;

const Section = styled.section`
  margin-top: 3rem;
`;

export default ({ pageContext: { searchIndex } }) => (
  <Layout background={true} searchIndex={searchIndex}>
    <PageTitle>Get in touch</PageTitle>
    <Grid container spacing={24} justify="center">
      <Grid item xs={12} sm={6} md={4}>
        <Section>
          <Textblob>
            We’d love to hear from you. For general enquiries, further
            information about flora&faunavisions, a full client list or press
            material, please feel free to send us an e-mail. Our studio manager
            will get back to you as promptly as possible.
          </Textblob>
        </Section>
        <Section>
          <ContactForm />
        </Section>
        <Section>
          <Subheading fontFamily="ffvCorporateFat, sans-serif">
            flora&faunavisions GmbH
          </Subheading>
          <SmallText>
            <p>
              STUDIO FOR DESIGN
              <br />
              Management: Leigh Sachwitz
            </p>
            <p>
              Ehrenbergstraße 2<br /> 10245 Berlin, Germany
            </p>
            <p>
              <a href="tel:+493044010280">
                <InternalLink small>t +49 (0)30 440 102 80</InternalLink>
              </a>
            </p>
            <p>
              Value added tax identification number:
              <br /> DE 814716077 Handelsregister: HRB 102371 Amtsgericht Berlin
            </p>
          </SmallText>
        </Section>
        <Section>
          <Subheading fontFamily="ffvCorporateFat, sans-serif">
            Studio Leigh Sachwitz
          </Subheading>
          <SmallText>
            <p>
              Leigh Sachwitz
              <br /> Ehrenbergstraße 2 <br />
              10245 Berlin, Germany
            </p>
            <p>
              <a href="tel:+493044010280">
                <InternalLink small>t +49 (0)30 440 102 80</InternalLink>
              </a>
            </p>
            <a
              href="http://www.leighsachwitz.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              <InternalLink small>www.leighsachwitz.com</InternalLink>
            </a>
            <p>
              Value added tax identification number:
              <br /> DE 235143695
            </p>
          </SmallText>
        </Section>
      </Grid>
    </Grid>
  </Layout>
);
