import styled from 'styled-components';

export default styled.button`
  background: #f6ff88;
  color: black;
  font-family: ffvCorporateFat, sans-serif;
  text-align: center;
  text-transform: uppercase;
  outline: none;
  border: none;
  padding: 0.7rem;
  padding-top: 0.9rem;
  margin-top: 1rem;
  cursor: pointer;
`;
