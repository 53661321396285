import React from 'react';
import styled from 'styled-components';

const Textblob = styled.div`
  font-family: ffvCorporateFat;
  font-weight: 100;
  font-size: ${props => props.fontSize || '1.2rem'};
  line-height: 1.5;
  color: ${props => (props.theme.black ? 'white' : 'black')};
  width: ${({ width }) => width || '100%'};
  margin: 4rem auto;
  & p {
    margin: 0 auto;
  }
  & p + p {
    text-indent: 4rem;
  }
  & ul {
    list-style-position: inside;
  }
`;

export default function(props) {
  return (
    <Textblob
      className={props.className}
      width={props.width}
      dangerouslySetInnerHTML={{ __html: props.children }}
      fontSize={props.fontSize}
      style={props.style}
    />
  );
}
