import React from 'react';
import styled from 'styled-components';

const Title = styled.h1`
  font-family: Helvetica Neue, Helvetica, sans-serif;
  // font-family: ffvCorporateBold, sans-serif;
  font-size: 3rem;
  letter-spacing: -1px;
  text-align: center;
  color: ${({ theme }) => (theme.black ? 'white' : 'black')};
`;

export default props => (
  <Title>
    <div>{props.children}</div>
  </Title>
);
