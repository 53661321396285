import React, { useState } from 'react';
import styled from 'styled-components';

import Input from './Input';
import Smalltext from './SmallText';
import SubmitButton from './SubmitButton';
import Textblob from './Textblob';

const Wrapper = styled.div`
  text-align: center;
`;

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
};

export default () => {
  const [formValues, setFormValues] = useState({
    name: '',
    mail: '',
    message: '',
  });

  const handleFormInput = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
    setSubmitState(false);
  };
  const [submitState, setSubmitState] = useState(false);

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitState('pending');
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'contact', ...formValues }),
    })
      .then(() => {
        setSubmitState(true);
        setFormValues({
          name: '',
          mail: '',
          message: '',
        });
      })
      .catch(error => console.log(error));
  };

  return (
    <Wrapper>
      <form
        name="contact"
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value="contact" />
        <Input
          type="text"
          value={formValues.name}
          name="name"
          placeholder="Your Name"
          onChange={handleFormInput}
        />
        <Input
          type="text"
          value={formValues.mail}
          name="mail"
          placeholder="Your e-mail address"
          onChange={handleFormInput}
        />
        <Input
          value={formValues.message}
          onChange={handleFormInput}
          name="message"
          rows={5}
          placeholder="Your Message"
          as="textarea"
        />
        <Smalltext>
          By submitting this form you consent with our privacy policy.
        </Smalltext>
        {submitState === 'pending' && (
          <Textblob style={{ marginTop: '1.5rem' }}>Sending…</Textblob>
        )}
        {submitState === true && (
          <Textblob style={{ marginTop: '1.5rem' }}>
            Thanks for your message!
          </Textblob>
        )}
        <SubmitButton onClick={handleSubmit}>send message</SubmitButton>
      </form>
    </Wrapper>
  );
};
